@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
   url("../src/asset/fonts/Poppins-Regular.ttf") format("truetype");
  }

body {
  margin: 0;
}

