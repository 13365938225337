.form-body {
    display: block;
    background: #FFFFFF;
    /* text boxes shadow */
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;    
    padding: 20px;
    margin-top: 0;
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 20px;
    text-align: center;
}

.form-inner { 
    display: inline-block;
    padding: 10px;
}

.form-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 42px;
    /* identical to box height, or 127% */
    letter-spacing: -0.3px;
    color: rgba(4, 177, 231, 0.67);
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-bottom: 15px;
    text-align: start;
    margin-left: 12px;
}

.thank-you-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    /* identical to box height, or 127% */
    letter-spacing: -0.3px;
    color: rgba(4, 177, 231, 0.67);
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-bottom: 15px;
    text-align: start;
}

.form-group {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.label-text {
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-top: 5px;
    margin-bottom: 8px;
    text-align: start;
}

.inputfield {
    box-sizing: border-box;
    padding: 8px;
    width: 393px;
    height: 48px;
    left: 0px;
    top: 100px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    /* text boxes shadow */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-bottom: 10px;
}
.zeroTop{
    top : 0px !important;
    outline: none;
}
.zeroTop div{
    border: none;
    min-height: 30px;
}
.zeroTop .css-tlfecz-indicatorContainer{
    padding-top: 3px;
}
.zeroTop .css-1okebmr-indicatorSeparator{
    margin-top: 0px;
}

.inputfield::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    color: #4A4A4A;
    mix-blend-mode: normal;
    opacity: 0.5;
    align-self: center;
}

.input-otp-block {
   display: block;
   text-align: center;
}


.otpfield {
    box-sizing: border-box;
    width: 42px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    /* text boxes shadow */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin: 8px;
    text-align: center;
}

.OtpError {
   display: block;
   text-align: center;
   font-family: "Poppins"; 
   color: red;
   font-size: 12px;
   font-weight: 600;
   margin: 5px;
}

.hideScroll{
    overflow: hidden;
    margin-bottom: 0px;
    padding: 0px;
}

@media only screen and (max-width: 992px){
    .form-body {
        margin-top: 40px;
        margin-left:10%;
        margin-right: 10%;
    }
}
@media only screen and (max-width: 768px){
    .form-body {
        margin-top: 40px;
        margin-left: 5%;
        margin-right: 5%;
    }
}
  @media only screen and (max-width: 510px){
    .form-body {
        box-shadow: none;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 10px;
    }
    .inputfield {
        width: 100%;
    }
    .form-group{
        padding: 0px;
        width: 100%;
    }
    .form-header{
        font-size: 33px;
    }
    .form-inner{
        display: block;
        padding: 0px 7%;
    }
}