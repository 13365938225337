.form-button {
    display: block;
    text-align: center;
    margin-top: 40px;
}

.custom-button {
    width: 238px;
    height: 48px;
    background: linear-gradient(87.82deg, #04B1E7 0%, #34D4D3 52.23%, #67F8BE 106.68%);
    /* btn shadow */
    box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    color: white;
    border: none;
    cursor: pointer;
    margin-bottom: 60%;
}

.custom-button-small {
    width: 140px;
    height: 48px;
    background: linear-gradient(87.82deg, #04B1E7 0%, #34D4D3 52.23%, #67F8BE 106.68%);
    /* btn shadow */
    box-shadow: 0px 6px 14px 1px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    color: white;
    border: none;
    cursor: pointer;
    text-align: center;
}

.button-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
}

