.pop-up {
    width: 300px;
    height: 150px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0,0 ,0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-self: center;
    text-align:  center;
    z-index: 999;
}

.popUp-text {
    font-family: "Poppins";
    font-weight: 500;
    font-size: 15px;
    color: #000;
}

.modalWrapper{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.185);
    display: flex;
    align-items: center;
    justify-content: center;
}