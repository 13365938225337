.label-text {
    display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-top: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
    text-align: start;
}

.inputfield {
    box-sizing: border-box;
    padding: 8px;
    width: 393px;
    height: 48px;
    left: 0px;
    top: 100px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    /* text boxes shadow */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-bottom: 10px;
}

.inputfield::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
    color: #4A4A4A;
    mix-blend-mode: normal;
    opacity: 0.5;
    align-self: center;
}

.inputfield-error {
    box-sizing: border-box;
    padding: 8px;
    width: 393px;
    height: 48px;
    left: 0px;
    top: 100px;
    background: #FFFFFF;
    border: 1px solid #F1F1F1;
    /* text boxes shadow */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-bottom: 10px;
}

.input-error {
    color: red;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    margin: 0;
    margin-left: 5px;
}