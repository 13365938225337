.header {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 10px;
  margin-bottom: 20px;
}

.header .logo {
  text-align: center;
}

.header .galaxiiLogo{
  height: 72px;
  width: 49px;
}

.header .logo-text {
  display: block;
  color: #000;
  margin: 0;
  align-self: center;
  font-family: 'Poppins';
}

.header .header-text {
    flex: 1;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    /* line-height: 52px; */
    /* identical to box height */
    margin-right: 85px;
    color: #000;
    align-self:flex-end;
    text-align: center;
}

@media only screen and (max-width: 850px){
  .header .header-text {
    font-size: 25px;
    margin-right: 6%;
  }
  .header .galaxiiLogo{
    width: 39px;
    height: 55px;
  }
}
@media only screen and (max-width: 550px){
  .header .header-text {
    font-size: 20px;
    margin-right: 8%;
    margin-left: 10px;
  }
  .header .galaxiiLogo{
    width: 30px;
    height: 45px;
  }
}